import React from "react"
import RecurringDonationStatus from "church_center/components/recurring_donation_status"
import NextDonation from "church_center/components/next_donation"
import RepeatableScheduleInput from "shared/components/repeatable_schedule_input"
import moment from "moment"
import { any, bool } from "prop-types"

export default class EditSchedule extends React.Component {
  static propTypes = {
    release_hold_at: any,
    on_hold_indefinitely: bool,
    next_occurrence: any,
  }

  constructor(props) {
    super(props)

    this.state = {
      ...this.props,
      status: this.getInitialStatus(),
      release_hold_at: this.props.release_hold_at || this.getTomorrow(),
    }
  }

  handleChangeSchedule = (schedule) => {
    this.setState({ schedule })
  }

  handleDateChange = (release_hold_at) => {
    this.setState({ release_hold_at })
  }

  handleStatusChange = (status) => {
    this.setState({ status })
  }

  getInitialStatus = () => {
    if (this.props.on_hold_indefinitely) {
      return "indefinite_hold"
    } else if (this.props.release_hold_at) {
      return "hold_until"
    } else {
      return "active"
    }
  }

  getToday = () => {
    return moment().format("YYYY-MM-DD")
  }

  getTomorrow = () => {
    return moment().add(1, "days").format("YYYY-MM-DD")
  }

  renderNextDonation = () => {
    switch (this.state.status) {
      case "indefinite_hold":
        return "No donations will occur until this hold is released."
      case "hold_until":
        return (
          <span>
            <span>Your next donation will be </span>
            <NextDonation
              schedule={this.state.schedule}
              start_date={this.state.release_hold_at}
              next_occurrence={this.props.next_occurrence}
            />
          </span>
        )
      case "active":
        return (
          <span>
            <span>Your next donation will be </span>
            <NextDonation
              schedule={this.state.schedule}
              start_date={this.getToday()}
              next_occurrence={this.props.next_occurrence}
            />
          </span>
        )
      default:
        return ""
    }
  }

  render() {
    return (
      <div>
        <div className="mb-2">
          <label htmlFor="recurring_donation[schedule]" style={{ marginBottom: -16 }}>
            Frequency
          </label>
          <RepeatableScheduleInput
            schedule={this.state.schedule}
            onChange={this.handleChangeSchedule}
          />
          <input
            type="hidden"
            name="recurring_donation[schedule]"
            value={JSON.stringify(this.state.schedule)}
          />
        </div>

        <div>
          <RecurringDonationStatus
            status={this.state.status}
            release_hold_at={this.state.release_hold_at}
            onStatusChange={this.handleStatusChange}
            onDateChange={this.handleDateChange}
            label="Status"
          />
          <div className="fw-500 mt-2 c-tint3">{this.renderNextDonation()}</div>
        </div>
      </div>
    )
  }
}
