export function getPaymentMethodIcon({ brand, method_type }) {
  const cardBrands = {
    Visa: "visa",
    MasterCard: "mastercard",
    "American Express": "amex",
    Discover: "discover",
  }

  switch (method_type) {
    case "card":
      return cardBrands[brand] || "credit-card"
    default:
      return "bank-account"
  }
}
