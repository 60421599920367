import { useState, useCallback } from "react"

export default function useToggle(initialValue) {
  const [on, setOn] = useState(Boolean(initialValue))

  const toggle = useCallback((value) => {
    if (typeof value === "boolean") {
      return setOn(value)
    }

    return setOn((v) => !v)
  }, [])

  return [on, toggle]
}
