import React from "react"
import classNames from "classnames"
import Ladda from "shared/components/ladda"
import MoneyInput from "shared/components/money_input"
import PersonFormFields from "church_center/components/donation_form/person_form_fields"
import PaymentMethodFormFields from "church_center/components/donation_form/payment_method_form_fields"
import CaptchaForm from "church_center/components/captcha_form"
import * as coverTheFee from "church_center/utils/cover_the_fee"
import { designationsMinimalString } from "church_center/utils/designations"
import { ArrayFields, FormFor, HashFields, HiddenField } from "react-rails-form-helpers"
import { ccoPerson } from "shared/utils/prop_types"
import { arrayOf, bool, func, object, string, shape } from "prop-types"
import { USING_STORED_PAYMENT_METHOD } from "church_center/components/find_payment_method/legacy_find_payment_method"
import findPaymentMethodTokenResolver from "church_center/components/find_payment_method/find_payment_method_token_resolver"
import { css } from "glamor"
import { featureEnabled } from "shared/runners/flipper"
import { isGivingPaymentMethodId } from "church_center/utils/stripe"

SubmitDonationForm.propTypes = {
  designations: arrayOf(object).isRequired,
  loading: bool.isRequired,
  feeCoverageAllowed: bool.isRequired,
  isCoveringFee: bool.isRequired,
  storePaymentMethod: bool.isRequired,
  onSubmit: func.isRequired,
  onChangeCoveringFee: func.isRequired,
  onChangeStorePaymentMethod: func.isRequired,
  paymentMethod: shape({
    strategy: string.isRequired,
    paymentMethodType: string.isRequired,
  }).isRequired,
  person: ccoPerson.isRequired,
  url: string.isRequired,
}

function SubmitDonationForm({
  designations,
  loading,
  feeCoverageAllowed,
  isCoveringFee,
  storePaymentMethod,
  onSubmit,
  onChangeCoveringFee,
  onChangeStorePaymentMethod,
  paymentMethod,
  person,
  url,
}) {
  const { designationsCoveringFee, coveredFeeCents } = coverTheFee.calculate(
    designations,
    paymentMethod.paymentMethodType
  )
  const selectedDesignations = isCoveringFee ? designationsCoveringFee : designations
  const totalAmount = designationsMinimalString(selectedDesignations)
  const isUsingStoredPaymentMethod = featureEnabled("stripe_payment_element")
    ? isGivingPaymentMethodId(paymentMethod.paymentMethodId)
    : paymentMethod.strategy === USING_STORED_PAYMENT_METHOD
  const coveredFeeAmount = MoneyInput.minimalTextValue(coveredFeeCents / 100)

  const isUsingBankAccount =
    paymentMethod.paymentMethodType && paymentMethod.paymentMethodType !== "card"

  return (
    <CaptchaForm Form={FormFor} url={url} method="post" data-remote onSubmit={onSubmit}>
      <HashFields name="donation">
        <ArrayFields name="designations_attributes">
          {selectedDesignations.map(({ fund_id, amount }, index) => (
            <HashFields key={index}>
              <HiddenField name="fund_id" value={fund_id} />
              <HiddenField name="amount" value={amount} />
            </HashFields>
          ))}
        </ArrayFields>
        <HiddenField name="fee_covered" value={isCoveringFee} />
      </HashFields>

      <PersonFormFields person={person} />
      <PaymentMethodFormFields paymentMethod={paymentMethod} />

      <div>
        {!isUsingStoredPaymentMethod && (
          <div
            className="fs-4 mb-1 action-drawer px-2"
            {...css({
              borderRadius: "0 0 4px 4px",
              paddingTop: 0,
              paddingBottom: 12,
            })}
          >
            {isUsingBankAccount ? (
              <div className="pl-0 pl-6@sm pl-0@iframe pb-1 pt-4p">
                This bank account will be saved to your {person.email_address} donor profile as a
                verified bank account.
              </div>
            ) : (
              <div
                className={classNames("pl-0 pl-0@iframe pb-1 pt-4p", {
                  "pl-6@sm": !featureEnabled("stripe_payment_element"),
                })}
              >
                <input
                  className="checkbox"
                  id="stripe_store_payment_method"
                  name="stripe[store_payment_method]"
                  type="checkbox"
                  value="true"
                  checked={storePaymentMethod}
                  onChange={(event) => onChangeStorePaymentMethod(event.target.checked)}
                />
                <label
                  className="checkbox-label pb-0"
                  htmlFor="stripe_store_payment_method"
                  {...css({
                    "&:before": { top: "1px !important" },
                    "&:after": { top: "4px !important" },
                  })}
                >
                  Save this payment method
                </label>
              </div>
            )}
          </div>
        )}

        {feeCoverageAllowed && paymentMethod.paymentMethodType && (
          <div className="fs-4 my-2">
            <input
              className="checkbox"
              id="covering_fee"
              type="checkbox"
              checked={isCoveringFee}
              onChange={(event) => {
                onChangeCoveringFee(event.target.checked)
              }}
            />
            <label className="checkbox-label" htmlFor="covering_fee">
              {`Add $${coveredFeeAmount} to cover the processing fee`}
            </label>
            {!isUsingBankAccount && (
              <div className="fs-5 c-tint3 pl-3">
                Note: {I18n.t("ACH")} bank transfers only cost $0.
                {coverTheFee.getFeeRates("bank_account").flat_cents}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="mt-3 ta-c">
        <Ladda button loading={loading} className="btn" data-cy="submit_donation_form_button">
          Give ${totalAmount} now
        </Ladda>
      </div>
    </CaptchaForm>
  )
}

export default findPaymentMethodTokenResolver(SubmitDonationForm)
