import React from "react"
import MoneyInput from "shared/components/money_input"
import Icon from "church_center/components/external_icon"
import colors from "church_center/utils/colors"
import { css } from "glamor"
import { fund as fundPropType, designation as designationPropType } from "shared/utils/prop_types"
import { arrayOf, func } from "prop-types"
import { designationsMinimalString } from "church_center/utils/designations"

export default class DesignationsInput extends React.Component {
  static propTypes = {
    designations: arrayOf(designationPropType).isRequired,
    funds: arrayOf(fundPropType).isRequired,
    onChange: func.isRequired,
  }

  state = {
    windowWidth: 0,
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize)
    this.setState({ windowWidth: window.innerWidth })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize)
  }

  handleWindowResize = (e) => {
    this.setState({ windowWidth: e.target.innerWidth })
  }

  handleAddDonationClick = (e) => {
    const { designations, onChange } = this.props
    const fund = this.availableFundsFor({})[0]

    const newDesignations = [
      ...designations,
      {
        fund_id: fund.id,
        amount: "",
        name: fund.name,
        description: fund.description,
      },
    ]
    onChange(newDesignations)
    e.preventDefault()
  }

  handleSelectChange = (e, designation) => {
    const { funds, designations, onChange } = this.props
    const fund = funds.find((f) => f.id.toString() === e.target.value.toString())

    const nextDesignations = designations.map((d) => {
      if (d === designation) {
        return {
          fund_id: fund.id,
          name: fund.name,
          description: fund.description,
          amount: designation.amount,
        }
      } else {
        return d
      }
    })

    onChange(nextDesignations)
  }

  handleRemoveDesignation = (e, designation) => {
    e.preventDefault()

    const { designations, onChange } = this.props
    const nextDesignations = designations.filter((d) => d !== designation)

    onChange(nextDesignations)
  }

  handleBlurAmount = ({ target: { value } }, designation) => {
    const amount = MoneyInput.minimalTextValue(value)
    this.handleChangeAmount({ target: { value: amount } }, designation)
  }

  handleChangeAmount = ({ target: { value: amount }, nativeEvent }, designation) => {
    const { designations, onChange } = this.props

    const shouldIgnoreInput =
      nativeEvent &&
      nativeEvent.inputType === "insertText" &&
      nativeEvent.data !== "." &&
      amount === ""

    if (shouldIgnoreInput) return

    const nextDesignations = designations.map((d) => {
      if (d === designation) {
        return { ...d, amount }
      } else {
        return d
      }
    })
    onChange(nextDesignations)
  }

  availableFundsFor = (designation) => {
    const { funds, designations } = this.props
    const fundIds = designations.map((d) => d.fund_id)
    return funds.filter((f) => f.id === designation.fund_id || fundIds.indexOf(f.id) < 0)
  }

  getTotal = () => {
    const { designations } = this.props

    return designationsMinimalString(designations)
  }

  renderSelectAndInput(designation) {
    const select = (
      <div>
        <label
          htmlFor={`${designation.fund_id}_select`}
          className="screen-reader-text"
          aria-label="Fund"
        >
          Fund
        </label>
        <select
          name={`${designation.fund_id}_select`}
          id={`${designation.fund_id}_select`}
          value={designation.fund_id}
          onChange={(e) => this.handleSelectChange(e, designation)}
          className="select"
          data-cy="fund_select"
        >
          {this.availableFundsFor(designation).map((fund) => (
            <option key={fund.id} value={fund.id}>
              {fund.name}
            </option>
          ))}
        </select>
      </div>
    )
    const input = this.renderDesignationInput(designation)
    const description = this.renderDesignationDescription(designation)
    const { designations } = this.props
    return (
      <div className="action-drawer mb-1 d-f@sm d-b@iframe ai-fs">
        <div
          className="mb-1 mb-0@sm mb-1@iframe mr-1@sm"
          {...css({
            "@media (min-width: 600px)": {
              width: 200,

              ".iframed &": { width: "100%" },
            },
          })}
        >
          {input}
        </div>
        <div className="f-1">
          {select}
          {description}
        </div>
        {designations.length > 1 && (
          <div className="d-f d-if@md jc-fe mt-1 m-0@md">
            <button
              onClick={(e) => this.handleRemoveDesignation(e, designation)}
              className="clickable-icon text-btn c-ruby d-f ai-c jc-c"
              aria-label="remove donation designation"
              {...css({
                borderRadius: 4,
                "@media (min-width: 600px)": {
                  fontSize: "14px !important",
                  marginRight: "-8px",
                  marginLeft: "4px",
                },
              })}
            >
              <Icon symbol="general#x" />
              <span className="d-b@iframe d-n@sm pl-4p" style={{ lineHeight: 0 }}>
                remove
              </span>
            </button>
          </div>
        )}
      </div>
    )
  }

  renderDesignationInput(designation) {
    const workAround1PasswordAutofillConsideringThisTheCreditCardMonth = {
      name: "quantity",
    }

    return (
      <div className="prepend-label">
        <label
          htmlFor={`${designation.fund_id}_input`}
          className="label"
          {...css({ backgroundColor: "transparent !important" })}
        >
          <span className="screen-reader-text">Amount</span>$
        </label>
        <input
          type="number"
          autoComplete="off"
          autoFocus={true}
          className="label-container"
          {...css({ paddingLeft: "26px !important" })}
          data-cy="designations_input_amount"
          id={`${designation.fund_id}_input`}
          onBlur={(e) => this.handleBlurAmount(e, designation)}
          onChange={(e) => this.handleChangeAmount(e, designation)}
          placeholder="0"
          value={designation.amount}
          {...workAround1PasswordAutofillConsideringThisTheCreditCardMonth}
        />
      </div>
    )
  }

  renderDesignationDescription(designation) {
    return (
      <div>
        {designation.description && (
          <div className="d-f pt-1 px-1 ai-fs jc-sb">
            <span className="c-tint2 fs-13">{designation.description}</span>
          </div>
        )}
      </div>
    )
  }

  renderSingleFund() {
    const designation = this.props.designations[0]
    const input = this.renderDesignationInput(designation)

    return (
      <div>
        <label htmlFor="donation_form_donation_amount" className="screen-reader-text">
          Give
        </label>
        <div key={designation.fund_id}>
          {input}
          <div
            className="c-tint2 fs-13 m-0"
            {...css({
              backgroundColor: colors.tint9,
              borderRadius: "0 0 3px 3px",
              padding: ".75rem 1rem",
            })}
          >
            <span className="fw-500">{designation.name}</span>
            {designation.description && <span>:&nbsp;{designation.description}</span>}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { designations, funds } = this.props

    if (funds.length === 1) return this.renderSingleFund()

    return (
      <div>
        <div>
          {designations.map((designation) => (
            <div key={designation.fund_id} className="">
              {this.renderSelectAndInput(designation)}
            </div>
          ))}

          <div className="mt-1 d-f jc-sb">
            <div>{designations.length > 1 && <div>Total: ${this.getTotal()}</div>}&nbsp;</div>
            {designations.length < funds.length && (
              <button
                onClick={this.handleAddDonationClick}
                className="minor-btn secondary-btn btn"
                data-cy="add_designation_button"
              >
                <span className="mr-4p" style={{ fontSize: 9 }}>
                  <Icon symbol="general#plus" aria-hidden />
                </span>
                Add donation
              </button>
            )}
          </div>
        </div>
      </div>
    )
  }
}
