import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { number, arrayOf, object, string, func } from "prop-types"
import cx from "classnames"
import { css } from "glamor"
import Icon from "church_center/components/external_icon"
import PaymentMethodIcon from "church_center/components/find_payment_method/payment_method_icon"
import { getPaymentMethodIcon } from "church_center/utils/payment_methods"
import useToggle from "shared/hooks/use_toggle"
import useOutsideHandler from "shared/hooks/use_outside_handler"
import { resolveCssProperty } from "shared/utils/css"
import { hsla } from "shared/utils/adjust_colors"

const PaymentMethodLabel = (paymentMethod) => (
  <>
    <PaymentMethodIcon name={getPaymentMethodIcon(paymentMethod)} />
    <span className="f-1 ta-l">{paymentMethod.type_and_last4}</span>
  </>
)

PaymentMethodSelector.propTypes = {
  name: string.isRequired,
  onAddClick: func.isRequired,
  onChange: func.isRequired,
  paymentMethods: arrayOf(object).isRequired,
  selectedId: number,
}

export default function PaymentMethodSelector({
  name,
  onAddClick,
  onChange,
  paymentMethods,
  selectedId,
}) {
  const [activeId, setActiveId] = useState(selectedId)
  const [isExpanded, toggleExpanded] = useToggle(!selectedId)
  const selectedPaymentMethod = paymentMethods.find(({ id }) => id === selectedId)
  const activeBackgroundColor = hsla(resolveCssProperty("var(--color-brand)"), 0.05)

  const containerRef = useRef(null)
  const radioGroupRef = useRef(null)
  const toggleButtonRef = useRef(null)
  const toggleIntentRef = useRef(false)

  useEffect(() => {
    if (selectedId) {
      setActiveId(selectedId) // update the selected ID on parent prop changes
      toggleExpanded(false)
    }
  }, [selectedId, toggleExpanded])

  // manage focus when user intends to toggle the menu
  useLayoutEffect(() => {
    if (toggleIntentRef.current) {
      if (isExpanded) {
        // on expand, focus the selected radio or the first radio
        const checkedRadio = radioGroupRef.current?.querySelector("input:checked")
        checkedRadio ? checkedRadio.focus() : radioGroupRef.current?.querySelector("input")?.focus()
      } else {
        // on collapse, re-focus the toggle button
        toggleButtonRef.current?.focus()
      }
      toggleIntentRef.current = false // reset intent
    }
  }, [isExpanded])

  // select the active payment method when click/focus outside
  useOutsideHandler(containerRef, () => {
    if (isExpanded) {
      commitSelection()
    }
  })

  function handleKeydown(event) {
    if (event.key === "Enter" || event.key === " ") {
      event.preventDefault()
      toggleIntentRef.current = true
      commitSelection()
    }
  }

  function handleChange(event) {
    setActiveId(Number(event.target.value))
  }

  function handleToggleButtonClick() {
    toggleIntentRef.current = true
    if (isExpanded) {
      commitSelection()
    } else {
      toggleExpanded(true)
    }
  }

  function handleOptionClick(id) {
    toggleIntentRef.current = true
    commitSelection(id)
  }

  function handleAddClick() {
    onAddClick()
  }

  function commitSelection(id = activeId) {
    if (id !== selectedId) {
      setActiveId(id)
      onChange(id)
    }
    toggleExpanded(false)
  }

  return (
    <div className="mb-2" ref={containerRef}>
      <label id="payment-method-selector-label">Payment method</label>

      <div className="payment-method-selector">
        <button
          type="button"
          className="btn text-btn payment-method-selector__toggle"
          onClick={handleToggleButtonClick}
          ref={toggleButtonRef}
          aria-controls="payment-method-selector-menu"
          aria-expanded={isExpanded}
        >
          {selectedPaymentMethod && !isExpanded ? (
            <PaymentMethodLabel {...selectedPaymentMethod} />
          ) : (
            <span className="f-1 ta-l">Choose payment method</span>
          )}
          <Icon symbol="general#down-chevron" aria-hidden />
        </button>

        <div
          className={cx("fd-c g-1", { "d-n": !isExpanded, "d-f": isExpanded })}
          id="payment-method-selector-menu"
          data-testid="payment-method-selector-menu"
        >
          <div
            role="radiogroup"
            ref={radioGroupRef}
            aria-labelledby="payment-method-selector-label"
          >
            {paymentMethods.map((paymentMethod) => (
              <div
                className={cx("payment-method-selector__option", {
                  "payment-method-selector__option--active": activeId === paymentMethod.id,
                })}
                {...(activeId === paymentMethod.id &&
                  css({
                    backgroundColor: activeBackgroundColor,
                  }))}
                key={paymentMethod.id}
                onMouseUp={() => handleOptionClick(paymentMethod.id)}
              >
                <input
                  type="radio"
                  className="radio"
                  id={`payment_method_${paymentMethod.id}`}
                  name="paymentMethodSelector"
                  onChange={handleChange}
                  onKeyDown={handleKeydown}
                  value={paymentMethod.id}
                  checked={activeId === paymentMethod.id}
                />
                <label className="radio-label" htmlFor={`payment_method_${paymentMethod.id}`}>
                  <PaymentMethodLabel {...paymentMethod} />
                </label>
              </div>
            ))}
          </div>
          <div className="p-1">
            <button
              type="button"
              className="payment-method-selector__add-button btn compact-btn"
              onClick={handleAddClick}
            >
              <span
                className="d-f ai-c jc-c circle"
                {...css({
                  backgroundColor: "var(--color-tint6)",
                  width: "1.5em",
                  height: "1.5em",
                })}
              >
                <Icon symbol="general#plus" aria-hidden />
              </span>
              Add a payment method
            </button>
          </div>
        </div>
      </div>
      <input type="hidden" name={name} value={selectedId} />
    </div>
  )
}
