import React, { useState } from "react"
import { arrayOf, shape, string } from "prop-types"
import { turboAjax } from "shared/utils/turbolinks"
import Icon from "church_center/components/external_icon"
import RecurringDonation from "church_center/components/recurring_donation"
import * as GivingPropTypes from "shared/utils/prop_types"
import { RecurringDonationFundPropType } from "./edit_recurring_donation_form"

RecurringDonationsList.propTypes = {
  donations: arrayOf(GivingPropTypes.recurringDonation).isRequired,
  funds: arrayOf(RecurringDonationFundPropType).isRequired,
  routes: shape({ new_recurring_donation_url: string.isRequired }).isRequired,
}

export default function RecurringDonationsList({ donations, funds, routes }) {
  const [isEditingId, setIsEditingId] = useState(null)

  function handleEdit(id, url) {
    setIsEditingId(id)
    turboAjax({ url })
  }

  return (
    <section id="recurring_donations_collection">
      {donations.length > 0 ? (
        <>
          <div className="d-f jc-sb ai-c mb-3">
            <h2 className="h3">Recurring donations</h2>
            <a
              className="minor-btn secondary-btn btn"
              href={routes.new_recurring_donation_url}
              tabIndex="0"
              {...(isEditingId && { disabled: true })}
            >
              <span className="mr-4p" style={{ fontSize: 9 }}>
                <Icon symbol="general#plus" aria-hidden />
              </span>
              Add recurring donation
            </a>
          </div>
          {donations.map((donation) => (
            <RecurringDonation
              donation={donation}
              funds={funds}
              isDisabled={isEditingId && isEditingId !== donation.id}
              onEdit={(id) => handleEdit(id, donation.edit_url)}
              key={donation.id}
            />
          ))}
        </>
      ) : (
        <div className="ta-c c-tint3 p-4">
          <Icon
            symbol="general#recurring"
            aria-hidden
            style={{ width: 36, height: 36 }}
            className="mb-1"
          />
          <p>You don't have any recurring donations</p>
          <a className="btn mt-3" href={routes.new_recurring_donation_url}>
            Add recurring donation
          </a>
        </div>
      )}
    </section>
  )
}
