import React, { useRef, useState, useCallback, useEffect, Fragment } from "react"
import { object, bool, string, oneOf, arrayOf, func, number } from "prop-types"
import { ccoPerson, paymentMethod, inProgressDonationFormState } from "shared/utils/prop_types"
import ApplePayButton from "church_center/components/find_payment_method/apple_pay_button"
import * as StripeWithGuard from "shared/runners/stripe_with_guard"
import * as StripeSetupIntents from "church_center/runners/stripe_setup_intents"
import PaymentMethodList from "church_center/components/find_payment_method/payment_method_list"
import PaymentMethodChoice from "church_center/components/find_payment_method/payment_method_choice"
import { getPaymentMethodIcon } from "church_center/utils/payment_methods"

import moment from "moment"
import { css } from "glamor"
import ErrorMessage from "church_center/components/shared/error_message"
import { featureEnabled } from "shared/runners/flipper"
import Icon from "church_center/components/external_icon"
import SelectTestCard from "church_center/components/select_test_card"
import LoginButton from "church_center/components/find_payment_method/login_button"
import HorizontalRule from "church_center/components/find_payment_method/horizontal_rule"

import LegacyFindPaymentMethod, {
  STRIPE_CARD_SUCCESS,
  UNRESOLVED,
} from "church_center/components/find_payment_method/legacy_find_payment_method"

const STRIPE_PAYMENT_ELEMENT_STRATEGY = "STRIPE_PAYMENT_ELEMENT_STRATEGY"

const NO_CHOICE_SELECTED = "NO_CHOICE_SELECTED"
const STRIPE_PAYMENT_ELEMENT_CHOICE = "STRIPE_PAYMENT_ELEMENT_CHOICE"
const STORED_PAYMENT_METHOD_CHOICE = "STORED_PAYMENT_METHOD_CHOICE"

PaymentElementFindPaymentMethod.propTypes = {
  acceptApplePay: bool.isRequired,
  achAllowed: bool.isRequired,
  applePay: object.isRequired,
  children: func.isRequired,
  country: oneOf(["US", "CA"]),
  currency: oneOf(["USD", "CAD"]),
  feeCoverageAllowed: bool,
  isCoveringFee: bool,
  storePaymentMethod: bool.isRequired,
  isDonating: bool,
  loginRedirectUrl: string,
  newPaymentMethodUrl: string,
  onChangeCoveringFee: func,
  organizationAccountCenterId: number.isRequired,
  paymentMethods: arrayOf(paymentMethod),
  person: ccoPerson.isRequired,
  profilePaymentMethodsUrl: string,
  selectedPaymentMethodId: number,
}

function PaymentElementFindPaymentMethod({
  acceptApplePay,
  achAllowed,
  applePay,
  children,
  country,
  currency,
  feeCoverageAllowed,
  isCoveringFee,
  storePaymentMethod,
  isDonating = true,
  loginRedirectUrl,
  newPaymentMethodUrl,
  onChangeCoveringFee,
  organizationAccountCenterId,
  paymentMethods: storedPaymentMethods,
  person,
  profilePaymentMethodsUrl,
  selectedPaymentMethodId,
}) {
  const onlyRenderPaymentElement = !isDonating || !person.logged_in

  const stripeRef = useRef(null)
  const stripeElementsRef = useRef(null)
  const stripePaymentElementMountPointRef = useRef(null)

  const [paymentElementMounted, setPaymentElementMounted] = useState(false)
  const [selectedChoice, setSelectedChoice] = useState(
    onlyRenderPaymentElement ? STRIPE_PAYMENT_ELEMENT_CHOICE : NO_CHOICE_SELECTED
  )
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [paymentMethodType, setPaymentMethodType] = useState(null)
  const [applePaySupported, setApplePaySupported] = useState(false)
  const [strategy, setStrategy] = useState(STRIPE_PAYMENT_ELEMENT_STRATEGY)
  const [payload, setPayload] = useState({})
  const [stripeError, setStripeError] = useState(null)
  const [loading, setLoading] = useState(false)

  const loadStripe = async () => {
    await StripeWithGuard.promise()

    stripeRef.current = StripeWithGuard.instance()

    return
  }

  const handlePaymentElementChange = useCallback(
    (event) => {
      setPaymentMethodType(event.value.type)
    },
    [setPaymentMethodType]
  )

  const determineApplePaySupport = useCallback(() => {
    stripeRef.current
      .paymentRequest({
        country: country && country.toUpperCase(),
        currency: currency && currency.toLowerCase(),
        total: { amount: 1, label: "Check for Apple Pay Support" },
        displayItems: [],
      })
      .canMakePayment()
      .then((result) => {
        setApplePaySupported(result && result.applePay === true)
      })
  }, [country, currency])

  const mountPaymentElement = useCallback(async () => {
    if (
      stripePaymentElementMountPointRef.current &&
      !stripeElementsRef.current &&
      !paymentElementMounted
    ) {
      const clientSecret = await StripeSetupIntents.fetchClientSecret({
        personAccountCenterId: person.account_center_id,
        personEmailAddress: person.email_address,
      })

      stripeElementsRef.current = stripeRef.current.elements({ clientSecret })

      const stripePaymentElement = stripeElementsRef.current.create("payment", {
        fields: {
          billingDetails: { name: "auto", email: "never", phone: "auto", address: "auto" },
        },
        defaultValues: {
          billingDetails: { name: person.default_payment_method_name },
        },
        terms: {
          card: person.logged_in ? "auto" : "never",
        },
        wallets: {
          applePay: "never",
          googlePay: "never",
        },
      })
      stripePaymentElement.on("change", handlePaymentElementChange)
      stripePaymentElement.mount(stripePaymentElementMountPointRef.current)

      setPaymentElementMounted(true)
    }
  }, [
    paymentElementMounted,
    person.account_center_id,
    person.email_address,
    person.default_payment_method_name,
    person.logged_in,
    handlePaymentElementChange,
  ])

  const getPaymentMethod = useCallback(
    (id) => {
      if (storedPaymentMethods && storedPaymentMethods.length !== 0) {
        return storedPaymentMethods.find((pm) => pm.id === id)
      } else {
        return null
      }
    },
    [storedPaymentMethods]
  )

  useEffect(() => {
    loadStripe().then(() => {
      determineApplePaySupport()
      mountPaymentElement()
    })
  }, [determineApplePaySupport, mountPaymentElement])

  useEffect(() => {
    if (selectedPaymentMethodId) {
      setSelectedPaymentMethod(getPaymentMethod(selectedPaymentMethodId))
      setSelectedChoice(STORED_PAYMENT_METHOD_CHOICE)
    }
  }, [selectedPaymentMethodId, getPaymentMethod])

  useEffect(() => {
    setPaymentMethodType(selectedPaymentMethod?.method_type)
  }, [selectedPaymentMethod])

  const handleChooseStoredPaymentMethod = ({ id, method_type }) => {
    return () => {
      setSelectedPaymentMethod(getPaymentMethod(id))
      setPaymentMethodType(method_type)
      setSelectedChoice(STORED_PAYMENT_METHOD_CHOICE)
    }
  }

  const handleCancelChoice = () => {
    setSelectedPaymentMethod(null)
    setPaymentMethodType(null)
    setSelectedChoice(NO_CHOICE_SELECTED)
  }

  const handleStoredPaymentMethodSubmit = async () => {
    return true
  }

  const handleApplePaySuccess = (response, completion) => {
    setPayload({ response, applePay: { completion } })
    setSelectedPaymentMethod({
      id: response.paymentMethod.id,
      method_type: response.paymentMethod.type,
    })
    setStrategy(STRIPE_CARD_SUCCESS)
  }

  const handleApplePayClick = () => {
    setPaymentMethodType("card")
    setStrategy(UNRESOLVED)
  }

  const handleApplePayCancel = () => {
    setPaymentMethodType(null)
    setStrategy(STRIPE_PAYMENT_ELEMENT_STRATEGY)
  }

  const handleApplePayFailure = (error) => {
    setPaymentMethodType(null)
    setStrategy(STRIPE_PAYMENT_ELEMENT_STRATEGY)

    window.Bugsnag.notifyException(error, "ApplePayButton")
    alert(`Apple Pay: ${error.message}`)
  }

  const handleStripePaymentElementSubmit = async () => {
    setLoading(true)

    const response = await StripeSetupIntents.confirmSetupIntent({
      elements: stripeElementsRef.current,
      personName: person.default_payment_method_name,
      personEmailAddress: person.email_address,
      organizationAccountCenterId,
      singleUsePaymentMethod: !storePaymentMethod,
    })

    setLoading(false)

    if (!response.error) {
      setSelectedPaymentMethod({
        id: response.setupIntent.id,
        method_type: response.setupIntent.payment_method_types[0],
      })
      setStripeError(null)
      return true
    } else {
      setSelectedPaymentMethod(null)
      setStripeError(response.error)
      return false
    }
  }

  const handleSubmit = () => {
    switch (selectedChoice) {
      case STORED_PAYMENT_METHOD_CHOICE:
        return handleStoredPaymentMethodSubmit()
      case STRIPE_PAYMENT_ELEMENT_CHOICE:
        return handleStripePaymentElementSubmit()
      default:
        throw new Error("Unsupported choice submitted")
    }
  }

  const getBlockingError = () => {
    return null
  }

  const shouldShowTestUI = () => {
    return (
      featureEnabled("stripe_testmode") &&
      !featureEnabled("stripe_testmode_suppress_testcardmessage")
    )
  }

  const shouldShowChildren = () => {
    switch (selectedChoice) {
      case NO_CHOICE_SELECTED:
        return false
      case STORED_PAYMENT_METHOD_CHOICE: {
        const pm = selectedPaymentMethod
        if (pm && pm.verification_url) {
          return false
        } else {
          return true
        }
      }
      default:
        return true
    }
  }

  const usingApplePay = () => {
    return strategy === UNRESOLVED || strategy === STRIPE_CARD_SUCCESS
  }

  const shouldRenderChildren = () => {
    return shouldShowChildren() || usingApplePay()
  }

  const shouldRenderApplePayButton = () => {
    return (
      acceptApplePay &&
      applePaySupported &&
      isDonating &&
      selectedChoice !== STORED_PAYMENT_METHOD_CHOICE
    )
  }

  const renderApplePayButton = () => {
    return (
      shouldRenderApplePayButton() && (
        <>
          <ApplePayButton
            onSuccess={handleApplePaySuccess}
            onClick={handleApplePayClick}
            onCancel={handleApplePayCancel}
            onFailure={handleApplePayFailure}
            country={country}
            currency={currency}
            feeCoverageAllowed={feeCoverageAllowed && person.logged_in}
            isCoveringFee={isCoveringFee}
            onChangeCoveringFee={onChangeCoveringFee}
            {...applePay}
          />
          <HorizontalRule>OR</HorizontalRule>
        </>
      )
    )
  }

  const isRecent = (time) => {
    const secondsSince = moment.utc().diff(moment.utc(time), "seconds")
    return secondsSince < 30 ? true : false
  }

  const renderPaymentMethodChoices = () => {
    const addPaymentMethodChoiceActionProps = {
      href: newPaymentMethodUrl,
      remote: true,
    }

    switch (selectedChoice) {
      case NO_CHOICE_SELECTED: {
        return (
          <Fragment>
            {storedPaymentMethods.length > 0 && (
              <div className="fs-4 mb-2">
                <a href={profilePaymentMethodsUrl} tabIndex="0">
                  Manage saved payment methods
                </a>
              </div>
            )}
            {storedPaymentMethods.map(
              ({ id, brand, method_type, type_and_last4, verification_url }) => (
                <PaymentMethodChoice
                  key={id}
                  onSelect={handleChooseStoredPaymentMethod({ id, method_type })}
                  icon={getPaymentMethodIcon({ brand, method_type })}
                  primary={type_and_last4}
                  badge={verification_url ? "Unverified" : null}
                />
              )
            )}
            <PaymentMethodChoice
              key="add payment method"
              icon="bank"
              primary="Add payment method"
              {...addPaymentMethodChoiceActionProps}
            />
          </Fragment>
        )
      }
      case STORED_PAYMENT_METHOD_CHOICE: {
        const { brand, method_type, type_and_last4, verification_url, verified_at, created_at } =
          selectedPaymentMethod

        let successMessage
        if (method_type === "us_bank_account" && verified_at && isRecent(verified_at)) {
          successMessage = "Verified"
        }

        return (
          <>
            <PaymentMethodChoice
              onCancel={handleCancelChoice}
              icon={getPaymentMethodIcon({ brand, method_type })}
              primary={type_and_last4}
              badge={verification_url ? "Unverified" : null}
              selected={true}
              successMessage={successMessage}
            />
            {verification_url && (
              <div className="action-drawer pt-0" {...css({ borderRadius: "0 0 3px 3px" })}>
                <div className="info-alert alert mb-0 ml-4 p-1">
                  To donate with this account, please complete the verification by following the
                  instructions sent to your email.
                </div>
                {!isRecent(created_at) && (
                  <div className="d-f w-100% mt-1 pl-4">
                    <a href={verification_url} data-remote="true" className="btn minor-btn">
                      Verify account
                    </a>
                  </div>
                )}
              </div>
            )}
          </>
        )
      }
      default: {
        return null
      }
    }
  }

  const renderReassuringMessage = () => {
    return (
      <div className="d-f ai-fs mb-2 mt-3 fs-5 c-tint3 p-r">
        <span className="pr-4p">
          <Icon symbol="general#lock" aria-hidden />
        </span>
        <em>
          Payment information is TLS encrypted and stored at{" "}
          <a
            href="//stripe.com/"
            className="fw-600"
            target="_blank"
            rel="noopener noreferrer"
            tabIndex="0"
          >
            Stripe
          </a>{" "}
          - a Level 1 PCI compliant payment processor.
          {achAllowed && (
            <span>
              {" "}
              Bank verification powered by{" "}
              <a
                href="//stripe.com"
                className="fw-600"
                target="_blank"
                rel="noopener noreferrer"
                tabIndex="0"
              >
                Stripe
              </a>
              .
            </span>
          )}
        </em>
      </div>
    )
  }

  const renderBlockingError = () => {
    return <ErrorMessage className="mb-0">{getBlockingError()}</ErrorMessage>
  }

  const renderChildren = () => {
    return (
      <div style={{ display: shouldShowChildren() ? "block" : "none" }}>
        {children({
          paymentMethod: {
            paymentMethodId: selectedPaymentMethod?.id,
            paymentMethodType,
            strategy,
            payload,
            verificationMethod: null, // TODO: Address this while handling unverified payment methods
          },
          loading,
          requestToken: handleSubmit,
          submitUnverifiedPaymentMethod: false, // TODO: Address this while handling unverified payment methods
          verificationMethod: null, // TODO: Address this while handling unverified payment methods
          autoSubmit: false, // TODO: Address this while handling unverified payment methods
        })}
      </div>
    )
  }

  return (
    <div>
      <h3 className="h4 mb-1">Select payment method</h3>
      {!person.logged_in && (
        <div>
          <div className="fs-4 mb-2">
            <LoginButton
              accountCenterId={person.account_center_id}
              emailAddress={person.email_address}
              redirectUrl={loginRedirectUrl}
              submitText={"Log in to access saved payment methods or add a bank account"}
            />
          </div>
        </div>
      )}
      {renderApplePayButton()}
      {onlyRenderPaymentElement ? (
        <div
          className="action-drawer px-2"
          {...css({
            borderRadius: "4px",
            marginTop: -2,
            paddingTop: 16,
            paddingBottom: 12,
          })}
        >
          {shouldShowTestUI() && <SelectTestCard />}
          <div ref={stripePaymentElementMountPointRef} />
          {stripeError && (
            <div className="mt-3 fs-4">
              <ErrorMessage className="mb-0">
                {stripeError.message || "Something went wrong. Please try again."}
              </ErrorMessage>
            </div>
          )}
        </div>
      ) : (
        <PaymentMethodList>{renderPaymentMethodChoices()}</PaymentMethodList>
      )}
      {getBlockingError() ? renderBlockingError() : shouldRenderChildren() && renderChildren()}
      {renderReassuringMessage()}
    </div>
  )
}

FindPaymentMethod.propTypes = {
  applePay: object.isRequired,
  acceptApplePay: bool.isRequired,
  cardGivingLevel: string.isRequired,
  country: oneOf(["US", "CA"]),
  currency: oneOf(["USD", "CAD"]),
  paymentMethods: arrayOf(paymentMethod),
  person: ccoPerson.isRequired,
  isRecurring: bool,
  achAllowed: bool.isRequired,
  children: func.isRequired,
  feeCoverageAllowed: bool,
  isCoveringFee: bool,
  storePaymentMethod: bool,
  onChangeCoveringFee: func,
  onReset: func,
  organizationAccountCenterId: number.isRequired,
  loginRedirectUrl: string,
  unhandledGivingError: string,
  isDonating: bool,
  inProgressDonationFormState,
  newInProgressDonationUrl: string,
  ttgSetup: bool,
  newPaymentMethodUrl: string,
  profilePaymentMethodsUrl: string,
  selectedPaymentMethodId: number,
}

export default function FindPaymentMethod(props) {
  if (featureEnabled("stripe_payment_element")) {
    return (
      <PaymentElementFindPaymentMethod
        acceptApplePay={props.acceptApplePay}
        achAllowed={props.achAllowed}
        applePay={props.applePay}
        country={props.country}
        currency={props.currency}
        feeCoverageAllowed={props.feeCoverageAllowed}
        isCoveringFee={props.isCoveringFee}
        storePaymentMethod={props.storePaymentMethod}
        isDonating={props.isDonating}
        loginRedirectUrl={props.loginRedirectUrl}
        newPaymentMethodUrl={props.newPaymentMethodUrl}
        onChangeCoveringFee={props.onChangeCoveringFee}
        organizationAccountCenterId={props.organizationAccountCenterId}
        paymentMethods={props.paymentMethods}
        person={props.person}
        profilePaymentMethodsUrl={props.profilePaymentMethodsUrl}
        selectedPaymentMethodId={props.selectedPaymentMethodId}
      >
        {props.children}
      </PaymentElementFindPaymentMethod>
    )
  } else {
    return <LegacyFindPaymentMethod {...props} />
  }
}

export { STRIPE_PAYMENT_ELEMENT_STRATEGY }
