import React, { useMemo } from "react"
import * as GivingPropTypes from "shared/utils/prop_types"
import MoneyInput from "shared/components/money_input"
import { arrayOf, func } from "prop-types"
import classNames from "classnames"
import { css } from "glamor"
import Icon from "church_center/components/external_icon"
import { designationsMinimalString } from "church_center/utils/designations"
import { RecurringDonationFundPropType } from "./edit_recurring_donation_form"

EditDesignations.propTypes = {
  designations: arrayOf(GivingPropTypes.designation).isRequired,
  funds: arrayOf(RecurringDonationFundPropType).isRequired,
  onChangeDesignations: func.isRequired,
}

export default function EditDesignations({ designations, funds, onChangeDesignations }) {
  const activeDesignations = useMemo(
    () => designations.filter((designation) => !designation.destroy),
    [designations]
  )

  function handleNewDesignationClick(event) {
    event.preventDefault()
    const fund = availableFundsFor({})[0]

    const nextDesignations = [
      ...designations,
      {
        fund_id: fund.id,
        amount: 0,
        name: fund.name,
        description: fund.description,
      },
    ]
    onChangeDesignations(nextDesignations)
  }

  function handleRemoveDesignation(index, designation) {
    const nextDesignations = [...designations]
    if (designation.id) {
      nextDesignations[index].destroy = true // flag preexisting designation to be destroyed in Rails
    } else {
      nextDesignations.splice(index, 1) // otherwise, remove designation from client array
    }
    onChangeDesignations(nextDesignations)
  }

  function handleChangeAmount({ target: { value: amount }, nativeEvent }, index) {
    const shouldIgnoreInput =
      nativeEvent &&
      nativeEvent.inputType === "insertText" &&
      nativeEvent.data !== "." &&
      amount === ""

    if (shouldIgnoreInput) return

    const nextDesignations = [...designations]
    nextDesignations[index] = {
      ...nextDesignations[index],
      amount: amount,
    }
    onChangeDesignations(nextDesignations)
  }

  function handleBlurAmount({ target: { value } }, index) {
    const amount = MoneyInput.minimalTextValue(value)
    handleChangeAmount({ target: { value: amount } }, index)
  }

  function handleChangeSelectedFund(event, index) {
    const fund = funds.find((f) => f.id.toString() === event.target.value.toString())

    const nextDesignations = [...designations]
    nextDesignations[index] = {
      ...nextDesignations[index],
      fund_id: fund.id,
      name: fund.name,
      description: fund.description,
    }

    onChangeDesignations(nextDesignations)
  }

  function availableFundsFor(designation) {
    const fundIds = activeDesignations.map((d) => d.fund_id)
    return funds.filter((f) => f.id === designation.fund_id || fundIds.indexOf(f.id) < 0)
  }

  function getDesignationFund(designation) {
    return funds.find((f) => f.id === designation.fund_id)
  }

  function renderDestroyField(designation) {
    return (
      <input
        type="hidden"
        value={designation.destroy}
        name={"recurring_donation[designations_attributes][][_destroy]"}
      />
    )
  }

  function renderAmountAndFundFields(designation, index) {
    const { description: fundDescription } = getDesignationFund(designation)

    return (
      <>
        <div
          className="f-2"
          {...css({
            "@media (min-width: 600px)": {
              maxWidth: "60%",
            },
          })}
        >
          <div>
            <label
              htmlFor={"recurring_donation[designations_attributes][][fund_id]"}
              className="screen-reader-text"
              aria-label="Fund"
            >
              Fund
            </label>
            <select
              name={"recurring_donation[designations_attributes][][fund_id]"}
              className="select"
              value={designation.fund_id}
              onChange={(e) => handleChangeSelectedFund(e, index)}
            >
              {availableFundsFor(designation).map((fund, index) => (
                <option key={index} value={fund.id}>
                  {fund.name}
                </option>
              ))}
            </select>
          </div>
          {fundDescription && (
            <div>
              <div className="d-f pt-1 px-1 ai-fs jc-sb">
                <span className="c-tint2 fs-13">{getDesignationFund(designation).description}</span>
              </div>
            </div>
          )}
        </div>
        <div
          className="f-1@sm"
          {...css({
            "@media (min-width: 600px)": {
              minWidth: 150,
            },
          })}
        >
          <div className="prepend-label">
            <label
              htmlFor={"recurring_donation[designations_attributes][][amount]"}
              className="label"
              {...css({ backgroundColor: "transparent !important" })}
            >
              <span className="screen-reader-text">Amount</span>$
            </label>
            <input
              type="number"
              autoComplete="off"
              autoFocus={true}
              className="label-container"
              {...css({
                paddingLeft: "26px !important",
                textAlign: "right",
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              })}
              data-cy="designations_input_amount"
              name={"recurring_donation[designations_attributes][][amount]"}
              id={"recurring_donation[designations_attributes][][amount]"}
              onBlur={(e) => handleBlurAmount(e, index)}
              onChange={(e) => handleChangeAmount(e, index)}
              placeholder="0"
              value={designation.amount}
            />
          </div>
        </div>
      </>
    )
  }

  function renderNewDesignationLink() {
    return (
      <div className="d-f jc-sb ai-c">
        <button
          type="button"
          onClick={handleNewDesignationClick}
          className="minor-btn secondary-btn btn"
        >
          <span className="mr-4p" style={{ fontSize: 9 }}>
            <Icon symbol="general#plus" aria-hidden />
          </span>
          Add donation
        </button>
        {activeDesignations.length > 1 && (
          <div
            {...css({
              "@media (min-width: 600px)": {
                paddingInline: "12px",
                marginInlineEnd: "36px",
                width: 200,
                display: "flex",
                justifyContent: "space-between",
              },
            })}
          >
            <span>Total:</span> ${designationsMinimalString(activeDesignations)}
          </div>
        )}
      </div>
    )
  }

  function renderDestroyButton(designation, index) {
    return (
      <div className="d-f d-if@md jc-fe">
        <button
          type="button"
          onClick={() => handleRemoveDesignation(index, designation)}
          className="clickable-icon text-btn c-ruby d-f ai-c jc-c has-tip"
          data-tip="Remove"
          aria-label="Remove donation designation"
          {...css({
            borderRadius: 4,
            "@media (min-width: 600px)": {
              marginRight: "-8px",
              marginLeft: "-2px",
            },
          })}
        >
          <Icon symbol="general#x" />
          <span className="d-n@sm pl-4p" style={{ lineHeight: 0 }}>
            Remove
          </span>
        </button>
      </div>
    )
  }

  return (
    <div className="mb-2">
      {designations.map((designation, index) => (
        <div
          key={index}
          className={classNames("d-f fd-c fd-r@sm w-100% jc-sb ai-fs@sm g-1", {
            "mb-2": !designation.destroy,
          })}
        >
          {designation.id && (
            <input
              type="hidden"
              value={designation.id}
              name={"recurring_donation[designations_attributes][][id]"}
            />
          )}
          {designation.destroy ? (
            renderDestroyField(designation)
          ) : (
            <>
              {renderAmountAndFundFields(designation, index)}
              {activeDesignations.length > 1 && renderDestroyButton(designation, index)}
            </>
          )}
        </div>
      ))}
      {activeDesignations.length < funds.length && renderNewDesignationLink()}
    </div>
  )
}
