import React from "react"
import * as GivingPropTypes from "shared/utils/prop_types"
import { arrayOf, bool, func } from "prop-types"
import { css } from "glamor"
import Icon from "church_center/components/external_icon"
import classNames from "classnames"
import { getPaymentMethodIcon } from "church_center/utils/payment_methods"
import PaymentMethodIcon from "church_center/components/find_payment_method/payment_method_icon"
import i18n from "shared/runners/i18n"
import moment from "moment"
import { RecurringDonationFundPropType } from "./edit_recurring_donation_form"

RecurringDonation.propTypes = {
  donation: GivingPropTypes.donation.isRequired,
  funds: arrayOf(RecurringDonationFundPropType).isRequired,
  isDisabled: bool.isRequired,
  onEdit: func.isRequired,
}

export default function RecurringDonation({ donation, funds, isDisabled, onEdit }) {
  const getDesignationFund = (designation) => {
    return funds.find((fund) => fund.id === designation.fund_id)
  }

  return (
    <div
      className="action-drawer mb-2"
      {...(isDisabled && css({ opacity: 0.5, backgroundColor: "var(--color-tint6)" }))}
      id={`recurring_donation_${donation.id}`}
    >
      <div className="d-f ai-c js-sb p-r">
        <div className="pr-1 f-1">
          <div className="d-f ai-c mb-4p">
            <span className="fw-600">
              ${parseFloat(donation.amount).toFixed(2)} {donation.schedule}
            </span>
            {donation.on_hold && <span className="badge warning-badge ml-1@sm">On hold</span>}
          </div>
          <div className="c-tint2 fs-4">
            {donation.designations.map((designation, index) => (
              <div
                key={index}
                className={classNames({ "mt-0": index === 0, "mt-2p": index !== 0 })}
              >
                <span className="badge mr-1">{getDesignationFund(designation).name}</span>$
                {parseFloat(designation.amount).toFixed(2)}
              </div>
            ))}
            {!donation.on_hold_indefinitely && (
              <div className="mt-2p">
                Next donation: {moment(donation.next_occurrence).format(i18n.dateFormatMoment)}
              </div>
            )}
          </div>
          {!donation.payment_method ? (
            <div className="alert error-alert d-if ai-c mt-4p mb-0">
              <Icon symbol="general#exclamation-triangle" className="mr-1 d-if" aria-hidden />
              <p className="fs-4 mb-0">Donation needs payment method</p>
            </div>
          ) : (
            <div className="payment-method-badge">
              <span className="payment-method-badge__icon">
                <PaymentMethodIcon
                  name={getPaymentMethodIcon({
                    brand: donation.payment_method.brand,
                    method_type: donation.payment_method.method_type,
                  })}
                />
              </span>
              <span className="payment-method-badge__meta fs-4 pb-4p">
                {donation.payment_method.type_and_last4}
              </span>
            </div>
          )}
        </div>
        <button
          type="button"
          onClick={() => onEdit(donation.id)}
          aria-label="Edit this donation"
          className="clickable-icon text-btn d-if"
          style={{ borderRadius: 4 }}
          tabIndex="0"
          disabled={isDisabled}
        >
          <Icon symbol="general#pencil" title="pencil" aria-hidden />
        </button>
      </div>
    </div>
  )
}
