import { useEffect } from "react"

/**
 * Hook to set document event handlers when an element is clicked/touched/focused outside.
 * @param {*} ref - React ref object
 * @param {Function} handler - function called when the event is triggered
 */
export default function useOutsideHandler(ref, handler) {
  useEffect(() => {
    const EVENTS = ["mousedown", "touchstart", "focusin"]

    const handleEvent = (event) => {
      if (!ref.current?.contains(event.target)) {
        handler(event)
      }
    }

    EVENTS.forEach((event) => {
      document.addEventListener(event, handleEvent)
    })

    return () => {
      EVENTS.forEach((event) => {
        document.removeEventListener(event, handleEvent)
      })
    }
  }, [ref, handler])
}
