import React, { useState, useEffect, useMemo } from "react"
import { FormFor } from "react-rails-form-helpers"
import { arrayOf, object, oneOfType, string, number, shape } from "prop-types"
import { turboAjax } from "shared/utils/turbolinks"
import EditSchedule from "church_center/components/edit_schedule"
import PaymentMethodSelector from "church_center/components/payment_method_selector"
import EditDesignations from "church_center/components/edit_designations"

export const RecurringDonationFundPropType = shape({
  brand: string.isRequired,
  id: oneOfType([number, string]).isRequired,
  method_type: string.isRequired,
  type_and_last4: string.isRequired,
})

EditRecurringDonationForm.propTypes = {
  donation: object.isRequired,
  funds: arrayOf(RecurringDonationFundPropType).isRequired,
  payment_methods: arrayOf(object).isRequired,
  routes: shape({
    new_payment_method_url: string.isRequired,
    profile_recurring_donations_url: string.isRequired,
    recurring_donation_url: string.isRequired,
  }).isRequired,
  selected_payment_method_id: number,
}

export default function EditRecurringDonationForm({
  donation,
  funds,
  payment_methods,
  routes,
  selected_payment_method_id,
}) {
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(
    selected_payment_method_id || donation.payment_method_id
  )
  const paymentMethods = useMemo(() => payment_methods, [payment_methods])
  const [designations, setDesignations] = useState(donation.designations)

  useEffect(() => {
    // sync changes downstream
    if (selected_payment_method_id) {
      setSelectedPaymentMethodId(selected_payment_method_id)
    }
  }, [selected_payment_method_id])

  function handleChangeDesignations(designations) {
    setDesignations(designations)
  }

  function handleAddPaymentMethodClick() {
    turboAjax({ url: routes.new_payment_method_url })
  }

  function handleDeleteClick(event) {
    event.preventDefault()

    sweetAlert(
      {
        title: "Delete recurring donation?",
        text: "This will remove all scheduled occurrences of this donation. If you change your mind, you'll have to create a new recurring donation.",
        type: "warning",
        customClass: "pco-sweet-alert--warning-destroy",
        confirmButtonText: "Delete",
        showCancelButton: true,
      },
      (confirm) => {
        if (confirm) {
          turboAjax({
            url: routes.recurring_donation_url,
            method: "DELETE",
            remote: true,
          })
        }
      }
    )
  }

  function handleCancelClick() {
    Turbolinks.visit(routes.profile_recurring_donations_url)
  }

  return (
    <FormFor
      id={`edit_recurring_donation_${donation.id}`}
      url={routes.recurring_donation_url}
      method="patch"
      data-remote
    >
      <header className="mb-2">
        <h3>Manage recurring donation</h3>
        <p className="mt-4p c-tint2">
          ${parseFloat(donation.amount).toFixed(2)} {donation.schedule.summary}
        </p>
      </header>
      <EditDesignations
        designations={designations}
        onChangeDesignations={handleChangeDesignations}
        funds={funds}
      />
      <PaymentMethodSelector
        name="recurring_donation[payment_method_id]"
        onAddClick={handleAddPaymentMethodClick}
        onChange={(id) => setSelectedPaymentMethodId(id)}
        paymentMethods={paymentMethods}
        selectedId={selectedPaymentMethodId}
      />
      <EditSchedule
        on_hold_indefinitely={donation.on_hold_indefinitely}
        release_hold_at={donation.release_hold_at}
        next_occurrence={donation.next_occurrence}
        schedule={donation.schedule.data}
      />
      <div className="mt-2 d-f ai-c jc-sb">
        <button type="button" onClick={handleDeleteClick} className="compact-btn destroy-btn btn">
          Delete
        </button>
        <div>
          <button type="button" className="compact-btn naked-btn" onClick={handleCancelClick}>
            Cancel
          </button>
          <button type="submit" className="compact-btn btn">
            Update donation
          </button>
        </div>
      </div>
    </FormFor>
  )
}
